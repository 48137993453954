html, body{
    min-width: 0;
}

.align-items-center {
    align-items: center !important;
}

.text-center {
    text-align: center!important;
}

.white {
    color: white!important;
}

.image-container {
    display: flex;
    justify-content: center;
}

.login-body {
    // background-image: url("/assets/fromlabs/background.png");
    background-image: none;
    background-repeat: repeat!important;
    // background-size: 10px;
    // overflow: auto;
    // position: relative;
    
    .fromlabs-logo-image{
        top: 5px;
        left: 5px;
        position: absolute;
        img{
            width: 50%;
        }
    }
    .login-panel{
        border: 1px solid #8faadc;
        z-index: 1000;
        height: auto;
        // margin-top: 30px;

        // @media screen and (min-width: 768px) {
        //     margin-top: 150px;
        // }

        .login-panel-content{
            padding: 5px 30px!important;
            h1 {
                font-size: xx-large!important;
                color: #8faadc;
                font-weight: unset;
            }
            .btn-sign-in {
                background-color: #8faadc;
            }
        }
        .fl-login-header{
            background-color: #1f2d40!important;
            img {
                width: 50%!important;
            }
        }
    }
    .login-background-image{
        position: absolute;
        bottom: 0px;
        width: 100%;
        img {
            width: 100%;
        }
    }
}

.reset-password {
    margin-left:15px;
}

.recover-username {
    margin-left:15px;
    margin-top:3px;
    height:3vh;
}

.message-login{
    padding-top: unset!important;
    padding-bottom: unset!important;
}

.btn-go-back {
    text-align: center;
    background-color: #8faadc;
}